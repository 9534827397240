@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "resources/assets/sass/font-awesome/font-awesome";
@import "resources/assets/css/dataTables.bootstrap.css";
//@import "resources/assets/css/jquery-confirm.min.css";

@import "//fonts.googleapis.com/css?family=Roboto:400,300";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;


html, body {
	height: 100%;
}

body {
  padding-top: 70px;
  padding-bottom: 30px;
}

.theme-dropdown .dropdown-menu {
  position: static;
  display: block;
  margin-bottom: 20px;
}

.theme-showcase > p > .btn {
  margin: 5px 0;
}

.theme-showcase .navbar .container {
  width: auto;
}

.title {
	font-size: 96px;
}


/* Font Awesome Styling */
i.fa.fa-times {
	color: red;
  cursor: pointer;
}

i.fa.fa-btn.fa-times {
	color: white;
}

/* Dashboard Panel Styling */
.panel-green {
    border-color: #5cb85c;
}

.panel-green .panel-heading {
    border-color: #5cb85c;
    color: #fff;
    background-color: #5cb85c;
}

.panel-green a {
    color: #5cb85c;
}

.panel-green a:hover {
    color: #3d8b3d;
}

.panel-red {
    border-color: #d9534f;
}

.panel-red .panel-heading {
    border-color: #d9534f;
    color: #fff;
    background-color: #d9534f;
}

.panel-red a {
    color: #d9534f;
}

.panel-red a:hover {
    color: #b52b27;
}

.panel-yellow {
    border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
    border-color: #f0ad4e;
    color: #fff;
    background-color: #f0ad4e;
}

.panel-yellow a {
    color: #f0ad4e;
}

.panel-yellow a:hover {
    color: #df8a13;
}


/* Laravel CSS styling */
/*
.container {
	text-align: center;
	display: table-cell;
	vertical-align: middle;
}

.content {
	text-align: center;
	display: inline-block;
}

body {
	margin: 0;
	padding: 0;
	width: 100%;
	display: table;
	font-weight: 100;
}
*/

